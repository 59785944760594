import consumer from "./consumer"

consumer.subscriptions.create("ChatroomChannel", {
  connected() {

  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel

    var a = new CustomEvent('chat-created', {"detail": {timestamp: data.created_at, notification: data.message.body}});
    document.dispatchEvent(a);


  }
});
