import consumer from "./consumer"

consumer.subscriptions.create("UserChannel", {
  connected() {
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {   
    if( data.event_name == 'user-notification-created' ) {
      var a = new CustomEvent(data.event_name, {"detail": {timestamp: data.created_at, notification: data.notification}});
    } else if (data.event_name == 'farming-slots-updated' ) {
      var a = new CustomEvent(data.event_name, {"detail": {slots: data.slots}});
    } else if (data.event_name == 'mining-page-updated' ) {
      var a = new CustomEvent(data.event_name, {"detail": {html: data.html}});
    } else if (data.event_name == 'smithing-page-updated' ) {
      var a = new CustomEvent(data.event_name, {"detail": {html: data.html}});
    } else if (data.event_name == "crafting-queue-updated" ) {
      var a = new CustomEvent(data.event_name, {"detail": {html: data.html}});
    }
    document.dispatchEvent(a);
  }
});
